import axios from "axios";
import { apiUrl } from "../constants/api";

export const me = async () => {
  const res = await axios.get(`${apiUrl}/users/me`, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  if(res && res.data) {
    return res.data.data;
  } 
  return null;
};

export const authenticate = async signinInfo => {
  const { data } = await axios.post(`${apiUrl}/users/authenticate`, signinInfo);
  return data.data;
};

export const updateMe = async userInfo => {
  const { data } = await axios.put(`${apiUrl}/users/update-me`, userInfo, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};

export const validateEmail = async validateInfo => {
  const { data } = await axios.post(
    `${apiUrl}/users/validate-email`,
    validateInfo
  );
  return data.data;
};

export const registerUser = async registerInfo => {
  const { data } = await axios.post(`${apiUrl}/users/register`, registerInfo);
  return data.data;
};

export const resetUser = async resetInfo => {
  const { data } = await axios.post(
    `${apiUrl}/users/reset-password`,
    resetInfo
  );
  return data;
};
export const contactUs = async contactInfo => {
  const { data } = await axios.post(`${apiUrl}/users/contactUs`, contactInfo);
  return data;
};

export const updateUserPassword = async userPasswordInfo => {
  const { data } = await axios.post(`${apiUrl}/users/new-password`, userPasswordInfo);
  return data;
};

