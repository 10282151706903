import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import queryString from 'query-string';
import styled from "styled-components";
import Auth from "../components/Auth";
import HeaderHome from "../components/headerHome";
import Loading from "../components/Loading";
import "../components/layout.scss";

const SubscriptionContainer = styled(Container)`
    padding-top: 160px;
`;

const Subscription = () => {
  const parsed = typeof window !== "undefined"
    ? queryString.parse(window.location.search)
    : {};
  if (typeof window !== "undefined") {
  }
  if (parsed.subscription_success) {
    // replace /child-program with /toddler-talk-plan-tutorial url
    //window.location.href = "/child-program";
    window.location.href = '/toddler-talk-plan-tutorial'
  }
  return (
    <Auth>
      <HeaderHome />
      <SubscriptionContainer>
        <Row className="justify-content-center">
          <Col md="6" className="text-center">
            {parsed.subscription_success && (
              <Loading />
            )}

            {parsed.subscription_failed && (
              <>
                <h4>Subscription Failed</h4>
                <p>Something went wrong with checking out your subscription.</p>
                <Button as="a" href="/pricing">Redirect back to pricing.</Button>
              </>
            )}
          </Col>
        </Row>
      </SubscriptionContainer>
    </Auth>
  );
}

export default Subscription;