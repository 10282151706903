import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { me } from "../api/users";

const all_routes = [
  '/about', '/account-information', '/child-program'
];

const Auth = ({ children }) => {
  const [isLoading, setLoading] = useState(true);


  const saveDetailsLocally = (email, childName) => {
    localStorage.setItem('email', email);
    localStorage.setItem('childName', childName);
  }

  const RenderError404 = () => {
    window.location.href = "/not-found";
  }

  useEffect(async () => {
    let path = window.location.pathname;

    try {
      const { isFinishedTakingQuiz, email, childName, isSubscriptionActive } = await me();
      saveDetailsLocally(email, childName);

      // if(!all_routes.includes(path)) {
      if (false) {
        RenderError404();
      }

      if (!isFinishedTakingQuiz) {
        if (path === '/quiz' || path === '/quiz/') {
          setLoading(false);
        } else {
          window.location.href = '/quiz';
        }
      }

      else if (isFinishedTakingQuiz) {
        if (isSubscriptionActive) {
          if (
            path === '/pricing' ||
            path === '/pricing/' ||
            path === '/findings' ||
            path === '/findings/' || path === "/toddler-talk-assessment-findings"
            || path === "/toddler-talk-assessment-findings/"
          ) {
            // replace /child-program with /toddler-talk-plan-tutorial url
            //window.location.href = "/child-program";
            window.location.href = '/toddler-talk-plan-tutorial'
          } else {
            setLoading(false);
          }
        } else {
          // if (
          //   path === '/findings' ||
          //   path === '/findings/' || path === '/child-program' || path === '/child-program/'
          // ) {
          //   setLoading(false);
          // } else if (path === '/pricing' ||
          //   path === '/pricing/'
          // ) {
          //   setLoading(false);
          // } else {
          //   window.location.href = "/findings";  replace findings with "/toddler-talk-assessment-findings"
          // }
          setLoading(false);
        }
      }

      else {
        setLoading(false);
      }
    }

    catch (err) {
      window.location.href = "/not-found";
    }
  }, []);


  return isLoading ? <Loading /> : <div>{children}</div>;

};

export default Auth;
